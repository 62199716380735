<template>
    <div> 
      <div class="graphic-container" style="max-height: 500px; overflow-y: scroll;">
        <apexchart
              type="bar" 
              :options="chartOptions"
              :series="rightBarChartSeries" 
              style="padding: 20px" 
          ></apexchart>
      </div>
  </div>
  </template>
  <script>
  import { GET_ITEMS, LOADING } from "@/core/services/store/REST.module";
  import VueApexCharts from "vue-apexcharts";
  import { mapGetters } from "vuex";
  import { LARAVEL_DATE_FORMAT } from "@/core/config/constant";
  import * as _ from "lodash";
  import moment from "moment";
  export default {
    name: "SupplierPerformanceReportRightBarChart",
    computed: {
      ...mapGetters({
        loading: LOADING,
      }),
    },
    components: {
      apexchart: VueApexCharts,
    },
    data() {
      return {
        chartCategories: [],  
        tmpSeries: [],
        series: [],
        labels: [],
        height:1000
      };
    },
    props: [
      "startDate",
      "endDate",
      "currencyRateOptions", 
      "onClickButton", 
      "rightBarChartSeries",
      "leftChartCategories"
    ],
    mounted() { 
    },
    created() {
      this.getItems();
    },
    computed: {
      chartOptions() {
      let tmpChartOptions = {
        chart: {
          type: "bar",
          height: this.height,
          width:500,
          stacked: true,
          stackType: "100%",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "Başarılı / Başarısız Sipariş Oranları",
          style: {
            fontSize: "15px",
            fontWeight: "bold",
            color: "#5cb7e0",
          },
        },
        xaxis: {
          categories: this.leftChartCategories,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (val) {
              return val + "%";
            },
          }
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
          onItemClick: {
            toggleDataSeries: false,
          },
        },
      };  
      return tmpChartOptions;
    },
    },
  
    methods: {
      getItems() { 
      },
    },
    watch: {
      onClickButton: function (newVal, oldVal) {
        if (newVal == true) {
          this.series = [];
          this.labels = [];
          this.getItems();
        }
      },
      leftChartCategories:function(){
        if(this.leftChartCategories.length < 4){
          this.height=400
        }
      }
    },
  };
  </script>
  <style scoped>
  .endorsement-title {
    font-size: 14px;
    font-weight: 800;
    text-align: center;
    margin-top: 5px;
    color: #5cb7e0;
  }
  </style>
  