<template>
  <div class="row">
    <div class="col-md-12">
      <div id="filter-card" class="row py-3 px-4">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="filterResult()">
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters">              
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="team_ids"
                  :not-list="false"
                  :options="teamList"
                  :title="$t('scope.Team') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  :isOpenMultiselect="true"
                ></custom-multi-select>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="supplier_ids"
                  :not-list="false"
                  :options="supplierList"
                  :title="$t('Tedarikçi') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="supplier_id"
                ></custom-multi-select>
              </div>  
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="company_ids"
                  :not-list="false"
                  :options="companyList"
                  :title="$t('order.customer') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="order_company_id"
                ></custom-multi-select>
              </div> 
             
            </div>
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters">
              <div class="col-lg-4 col-md-6 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    :model.sync="filters.start_date"
                    name="start_time"
                    title="general.start_date"
                  >
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    :model.sync="filters.end_date"
                    name="end_date"
                    title="general.end_date"
                  >
                  </date-filter>
                </div>
              </div>
              <div
                class="col-xl-1 col-lg-1 col-md-1 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click.prevent="filterResult()"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div style="display:flex">
      <div class="custom_box">
        <p>Toplam Sipariş Adeti: </p> 
        <p>{{ totalDataList.total_order_quantity.toLocaleString(
            "tr-TR",
            {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }
          ) }}</p>
      </div>
      
      <div class="custom_box">
        <p>Sambtex Başarı Oranı: </p>
        <p>{{ totalDataList.success_percentage.toLocaleString(
            "tr-TR",
            {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            }
          ) }}%</p>
      </div>

    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-6">
          <dashboard-box :title="$t('report.endorsemet_report')">
            <template v-slot:preview>
              <SupplierPerformanceReportLeftBarChart
                :start-date.sync="filters.start_date"
                :end-date.sync="filters.end_date"
                :onClickButton="onClickButton" 
                :leftBarChartSeries="leftBarChartSeries"
                :leftChartCategories="leftChartCategories"
              ></SupplierPerformanceReportLeftBarChart>
            </template>
          </dashboard-box>
        </div>
        <div class="col-6">
          <dashboard-box :title="$t('report.endorsemet_report')">
            <template v-slot:preview>
              <SupplierPerformanceReportRightBarChart
                :start-date.sync="filters.start_date"
                :end-date.sync="filters.end_date"
                :onClickButton="onClickButton" 
                :rightBarChartSeries="rightBarChartSeries" 
                :leftChartCategories="leftChartCategories"
              ></SupplierPerformanceReportRightBarChart>
            </template>
          </dashboard-box>
        </div>
      </div>

      <div class="col-md-12">
        <dashboard-box :title="$t('esc.esc_list')">
          <template v-slot:preview>
            <div @contextmenu.prevent="onContextMenu">
              <data-table
                :bordered="false"
                :borderless="true"
                :fields.sync="fields"
                :hover="false"
                :items="formattedReport"
                :no-border-collapse="true"
                :disabled-infinite-loading="false"
                :outlined="false"
                :page.sync="page"
                :perPage.sync="perPage"
                :striped="true"
                :infiniteId="infiniteId"
                tableVariant="''"
                @hitBottom="onHitBottom"
                :sortBy.sync="order"
                :sortDesc.sync="sort"
                @onClickView="onClickView"
                :sticky-header="true"
                :responsive="true"
              ></data-table> 
            </div>
          </template>
        </dashboard-box>      
      </div>
    </div>
    <b-modal id="select-order-list-modal" ref="select-order-list-modal" hide-header hide-header-close
      scrollable size="lg">           
          <div class="custom_header_text">
              {{ clickSupplierName }}
          </div>
          <div @contextmenu.prevent="onContextMenu">
            <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="orderFields"
              :hover="false"
              :items="performance_order_list"
              :no-border-collapse="true"
              :outlined="false"
              :striped="true"
              :disabled-infinite-loading="false"
              :infiniteId="infiniteId"
              tableVariant="''"
              :page.sync="page"
              :perPage.sync="perPage" 
              :sortBy.sync="order"
              :sortDesc.sync="sort"
              :responsive="true" 
              :sticky-header="true"
            ></data-table>
          </div> 
      <template #modal-footer>
        <div class="col-12 text-center">
          <button class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="closeSelectCompanyModal">
            {{ $t('general.close') }}
          </button>
        </div>
      </template>
    </b-modal> 
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import TopWaybillNav from "@/view/pages/waybill/layouts/index/TopWaybillNav";
import SecondWaybillNav from "@/view/pages/waybill/layouts/index/SecondWaybillNav";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import SupplierPerformanceReportLeftBarChart from "./SupplierPerformanceReportLeftBarChart";
import SupplierPerformanceReportRightBarChart from "./SupplierPerformanceReportRightBarChart";

import {
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
  ITEMS,
  SET_FILTER,
  FILTER,
} from "@/core/services/store/report/supplierPerformanceReport.module";

import { GET_ITEMS } from "@/core/services/store/REST.module";
import {
  LARAVEL_DATE_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
  MOMENT_SYSTEM_DATE_TIME_FORMAT,
} from "@/core/config/constant";
import * as _ from "lodash";
import ContextMenu from "@/assets/components/contextMenu/ContextMenu";
import {
  SUPPLIER_PERFORMANCE_REPORT_START_DATE_STORAGE_NAME,
  SUPPLIER_PERFORMANCE_REPORT_END_DATE_STORAGE_NAME,
} from "@/core/storage/storage-names";

export default {
  name: "SupplierPerformanceReport",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    TopWaybillNav,
    SecondWaybillNav,
    DateFilter,
    CustomMultiSelect,
    ContextMenu,
    SupplierPerformanceReportLeftBarChart,
    SupplierPerformanceReportRightBarChart
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER,
      items: ITEMS,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      },
    },
    formattedReport() {
      
      if (!this.items || this.items.data === null || this.items.data === undefined)
        return [];
      let temp = [];
      let self = this; 
      
      this.selfSuccessRatio = [];
      this.totalSuccessRatio = [];
      this.leftChartCategories = [];
      
      this.rightselfSuccessRatio=[];
      this.rightselfFailRatio=[];
      let total_final=0
      let total_rapor=0

      this.items.data.forEach((item) => {
        this.full_total += Number(item.full_total);
        total_final += Number(item.final_count);
        total_rapor += Number(item.total_count);
      });
      
      this.items.data.forEach((item) => { 
      

        self.leftChartCategories.push(item.workshop); 
        self.selfSuccessRatio.push(item.success_percentage.toFixed(0)+'%');
        self.totalSuccessRatio.push( 
          ((item.full_total / ( this.full_total == 0 ? 1 :  this.full_total)*100)).toFixed(0) + '%'
        );

        self.rightselfSuccessRatio.push( item.success_percentage.toFixed(2) );
        self.rightselfFailRatio.push( (100-item.success_percentage).toFixed(2) );
 
        temp.push({
          id : item.id,
          company_id : item.company_id,
          supplier_name : item.workshop,
          total_order_amount : Number(item.order_quantity).toLocaleString(
            "tr-TR",
            {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }
          ),
          upload_quantity:Number(item.upload_quantity).toLocaleString(
            "tr-TR",
            {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }
          ),
          final_count:Number(item.final_count).toLocaleString(
            "tr-TR",
            {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }
          ),
          total_count:Number(item.total_count).toLocaleString(
            "tr-TR",
            {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }
          ),
          success_percentage:  Number(item.success_percentage).toLocaleString(
            "tr-TR",
            {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            }
          )  + '%'
        });
      }); 
      this.full_ratio = (total_final/(total_rapor == 0 ? 1: total_rapor))*100
   
      this.leftBarChartSeries = [
        {
          name: "Sipariş Yüzdesi",
          data: this.totalSuccessRatio,
          color: "#2BAA1E",
        },
        {
          name: "Başarı Yüzdesi",
          data: this.selfSuccessRatio,
          color: "#6563A4",
        },
      ]; 

      this.rightBarChartSeries = [
        {
          name: "Başarı Yüzdesi",
          data: this.rightselfSuccessRatio,
          color: "#2BAA1E",
        },
        {
          name: "Başarısızlık Yüzdesi",
          data: this.rightselfFailRatio,
          color: "#F41516",
        },
      ];
      
      return temp;
    },
  },
  data() {
    return {
      // Filters & pagination
      infiniteId: 1,
      sort: "desc",
      order: "id",
      userOptions: [],
      search: null,
      paginate: [],
      orderSizeAndColorExportDateBodySizes: [],
      page: 1,
      perPage: 25,
      currentStoreType: 99999999,
      startDate: moment().subtract(30, "d"),
      endDate: moment().add(1, "d"),
      download: false,
      reportListUrl: "api/report/supplier-performance-report", 
      companies: [],
      orderList: [],
      tmpOrderData: [],
      tmpPackingListData: [],
      supplierCompanyOptions: [],
      moment: moment,
      onClickButton: false,
      fields: [ 
        {
          key: "supplier_name",
          label: this.$t("Tedarikçi"),
          sortable: false,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "total_order_amount",
          label: this.$t("Sipariş Adeti"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "upload_quantity",
          label: this.$t("Yüklenen Adeti"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        }, 
        {
          key: "final_count",
          label: this.$t("Final"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg",
        },
        {
          key: "total_count",
          label: this.$t("Toplam"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg",
        },
        {
          key: "success_percentage",
          label: this.$t("Başarı Yüzdesi"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "show_order_detail",
          label: this.$t("#"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
      ],      
      orderFields:[ 
        {
          key: "order_number",
          label: this.$t("Sipariş No"),
          sortable: false,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "customer_name",
          label: this.$t("Müşteri"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "order_date",
          label: this.$t("Tarih"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "order_quantity",
          label: this.$t("Sipariş Adeti"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg",
        },
        {
          key: "upload_quantity",
          label: this.$t("Yükleme Adeti"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        }, 
        {
          key: "final_quantity",
          label: this.$t("OK Final"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "total_count",
          label: this.$t("Toplam"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "success_percentage",
          label: this.$t("Başarı Yüzdesi"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
      ],
      team_ids:[],
      teamList:[],
      company_ids:[],
      supplier_ids:[],
      companyList: [], 
      supplierList:[],
      detail_order_list:[],
      leftBarChartSeries:[],
      rightBarChartSeries:[],
      leftChartCategories:[],
      selfSuccessRatio:[],
      totalSuccessRatio:[],      
      rightselfSuccessRatio:[],
      rightselfFailRatio:[],
      performance_order_list :[],
      clickSupplierName:'',
      full_total:0,
      full_ratio:0,
      totalDataList:{},
    };
  },
  methods: {
    ...mapMutations({
      setFilter: SET_FILTER,
    }),
    onClickView(payload) { 
      this.getSupplierPerformanceOrderListItems(payload);
      this.openSelectOrdersModal()
      
    },
    openSelectOrdersModal() {
      this.$refs['select-order-list-modal'].show();
    },
    closeSelectCompanyModal() {
      this.detail_order_list = [];
      this.$refs['select-order-list-modal'].hide();
    }, 
    getSupplierPerformanceOrderListItems(company_id) { 
      let filter_list=this.filters;  
      this.$set(
        filter_list,
        "company_id",
        company_id
      ); 
      this.$set(
        filter_list,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filter_list,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );

      if (this.team_ids){
        this.$set(filter_list, "team_ids", JSON.stringify(this.team_ids));
      }
      if (this.company_ids) {
        this.$set(filter_list, "company_ids", JSON.stringify(this.company_ids));
      }
      if (this.supplier_ids) {
        this.$set(filter_list, "supplier_ids", JSON.stringify(this.supplier_ids));
      } 

      this.$store
        .dispatch(GET_ITEMS, {
          filters: filter_list,
          url: "api/report/supplier-performance-report/"+company_id+"/order-list",
        })
        .then((result) => {       
          let temp = [];
          this.performance_order_list = []
          
          result.data.orders.forEach((item) => {   
             temp.push({
              order_number: item.order_number ?? '',
              customer_name: item.customer_name ?? '',
              order_date: item.order_date ?? '',
              order_quantity:Number(item.order_quantity).toLocaleString(
                "tr-TR",
                {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }
              ),
              upload_quantity:Number(item.upload_quantity).toLocaleString(
                "tr-TR",
                {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }
              ),
              final_quantity:Number(item.final_quantity).toLocaleString(
                "tr-TR",
                {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }
              ),
              total_count:Number(item.total_count).toLocaleString(
                "tr-TR",
                {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }
              ),
              success_percentage : parseFloat(item.final_quantity / (item.total_count==0 ? 1: item.total_count)*100).toFixed(1)+'%'
            });
          }); 

          this.performance_order_list = temp
          this.clickSupplierName = result.data.title
        });
    },
    filterResult() {
      localStorage.setItem(
        SUPPLIER_PERFORMANCE_REPORT_START_DATE_STORAGE_NAME,
        moment(this.filters.start_date)
      );
      localStorage.setItem(
        SUPPLIER_PERFORMANCE_REPORT_END_DATE_STORAGE_NAME,
        moment(this.filters.end_date)
      );
      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onClickButton = true;
      this.onHitBottom();
    },
    onHitBottom($state = null) {
      if (this.loading) {
        return;
      }
      let self = this;
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort); 
      if (this.team_ids){
        this.$set(filters, "team_ids", JSON.stringify(this.team_ids));
      }
      if (this.company_ids) {
        this.$set(filters, "company_ids", JSON.stringify(this.company_ids));
      }
      if (this.supplier_ids) {
        this.$set(filters, "supplier_ids", JSON.stringify(this.supplier_ids));
      } 
      self.$store
        .dispatch(HANDLE_INFINITE_SCROLL, {
          url: self.reportListUrl,
          filters: filters,
          $state: $state,
        })
        .then((result) => {
          this.onClickButton = false;
          if (result.status) {
            if ($state) {
              this.infiniteId++;
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });
    },
    onContextMenu(event) {
      event.preventDefault();
    },
    resetFilters() {
      (this.sort = "desc"),
        (this.order = "id"),
        this.setFilter({
          page: 1,
          per_page: 25,
          search: "",
          start_date: moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT),
          end_date: moment().add(1, "d").format(LARAVEL_DATE_FORMAT),
        });
    },
    setup() {
      let self = this,
      promises = []; 

      
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/team-list",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, { 
          url: "api/companies",
        })
      );  

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/supplier-list",
        })
      ); 

      Promise.all(promises).then((results) => {
        if (results[0].status) { 
          self.teamList = self.convertArrayToObjectByKey(
            results[0].data,
            "id",
            "user_name"
          );
        }

        if (results[1].status) {
          self.companyList = self.convertArrayToObjectByKey(
            results[1].data,
            "id",
            "name"
          );
        }
       
        if (results[2].status) {
          self.supplierList = self.convertArrayToObjectByKey(
            results[2].data,
            "id",
            "name"
          );
        }

      });
    },
    orderFilter(param){
      let params=''; 
      if(param.team_ids){
        params += '&team_id='+JSON.stringify(param.team_ids);
      }
      if(param.company_ids){
        params += '&company_id='+JSON.stringify(param.company_ids);
      } 
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/report/order-list-packing?"+params, 
        })
        .then((result) => { 
          if (result.status) {
            this.orderList = result.data
          } else {
            console.log({ error: result });
          }
        });
    },
    totalData(){  
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/report/supplier-performance-total-data", 
        })
        .then((result) => { 
          if (result.status) {
            let data = result.data 
            this.totalDataList = {
              total_order_quantity: data.total_order_quantity,
              success_percentage: data.success_percentage,
            } 
          } else {
            console.log({ error: result });
          }
        });
    },
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    this.totalData();
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("Atölye Başarı Raporu") },
    ]);

    if (!this.isUserGranted("EscTable", ["viewAny"])) {
      return false;
    }

    if (!this.filter) {
      this.resetFilters();
    }

    this.filters.end_date = localStorage.hasOwnProperty(
      SUPPLIER_PERFORMANCE_REPORT_END_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(SUPPLIER_PERFORMANCE_REPORT_END_DATE_STORAGE_NAME))
      : moment().add(1, "d").format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(
      SUPPLIER_PERFORMANCE_REPORT_START_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(SUPPLIER_PERFORMANCE_REPORT_START_DATE_STORAGE_NAME))
      : moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT);

    this.setup();    
    this.onClickButton = true;
    setTimeout(this.filterResult, 500);
  },
  watch: {
    sort() {
      this.filterResult();
    },
    order() {
      this.filterResult();
    },
    "team_ids"(value){ 
      this.orderFilter({'team_ids':value})      
    },
    "company_ids"(value){ 
      this.orderFilter({'company_ids':value})      
    },
  },
};
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}
.custom_header_text{
  margin-bottom:20px;
  padding:10px;
  background: linear-gradient(180deg, #7DC4E4 0%, #572D80 100%);
  color:white;
  font-size:22px;
  font-weight: 700;
  border-radius:5px;
}

.custom_box{
  margin-bottom:20px;
  padding:10px;
  border:1px solid grey;
  font-size:22px;
  font-weight: 700;
  border-radius:5px;
  height:100px;
  margin:20px 20px 30px 10px;
  box-shadow: 2px 2px 15px 2px #7DC4E4;
  text-align-last: center;
}
.custom_box  p{
  font-size:22px;
}
.b-table-sticky-header > .table.b-table > thead > tr > th {
  background-color: white !important;
}
.b-table-sticky-header {
  max-height: 500px;
}
.b-table-sticky-header > .table.b-table > tbody > tr > th {
  background-color: white !important;
}
</style>
