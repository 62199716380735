<template>
  <div>
    <div class="graphic-container" style="max-height: 500px; overflow-y: scroll;">
      <apexchart
        type="bar" 
        :options="chartOptions"
        :series="leftBarChartSeries" 
        style="padding: 20px" 
      ></apexchart>
    </div> 
  </div>
</template>
<script>
import { GET_ITEMS, LOADING } from "@/core/services/store/REST.module";
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import { LARAVEL_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import moment from "moment";
export default {
  name: "SupplierPerformanceReportLeftBarChart",
  props: [
    "startDate",
    "endDate", 
    "onClickButton", 
    "leftBarChartSeries",
    "leftChartCategories"
  ],
  mounted() { 
  },  
  created() {
    this.getItems();
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    chartOptions() {
      let length = 500;
      if (this.tmpSeries.length <= 15) {
        length = 750;
      } else {
        length = length + (this.tmpSeries.length - 7) * 30;
      }
      let tmpChartOptions = {
        chart: {
          type: 'bar',
          height: this.height,
          width:500
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          }
        },
        dataLabels: {
          enabled: true,
          offsetX: -3,
          style: {
            fontSize: '12px',
            colors: ['#fff']
          }
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: "Atölye Başarı ve Sipariş Oranları",
          style: {
            fontSize: "15px",
            fontWeight: "bold",
            color: "#5cb7e0",
          },
        },
        xaxis: {
          categories: this.leftChartCategories,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (val) {
              return val+'%';
            },
          },
        },
      }; 
      return tmpChartOptions;
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return { 
      tmpSeries: [], 
      chartText: "",
      height:1000
    };
  },

  methods: {
    getItems() {  
    },
  },
  watch: {
    onClickButton: function (newVal, oldVal) {
      if (newVal == true) {
        this.getItems();
      }
    },
    leftChartCategories:function(){
      if(this.leftChartCategories.length < 4){
        this.height=400
      }
    }
  },
};
</script>
<style scoped>
.info-text-background {
  background-color: #f2f2f7;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  font-weight: 500;
}
.box {
  width: 15px;
  height: 15px;
}
.incerment-box {
  background-color: #f63535;
}
.plus-box {
  background-color: #357af6;
}
.graphic-container {
  overflow-x: scroll;
  display: flex;
  position: relative;
}
</style>
